import React from "react"
import { Carousel, Container } from "react-bootstrap"

interface Props {
  items: {
    name: string
    title: string
    image: string
    buttonName: string
    buttonUrl: string
  }[]
  url?: string
}

/** const */
const HeroImage: React.FC<Props> = ({ items }) => {
  return (
    <>
      <Carousel controls={false} indicators={true} interval={5000}>
        {items.map((item) => {
          return (
            <Carousel.Item>
              <img src={item.image} className="d-block w-100" alt="" />
              <Carousel.Caption className="h-100 d-flex align-items-center">
                <div className="d-flex flex-column gap-2 align-items-start">
                  <h1 className="p-0 m-0 invest-image-name" style={{ float: "left" }}>
                    {item.name}
                  </h1>
                  <h2 className="fs-5 p-0 m-0 invest-image-title" style={{ opacity: "0.6" }}>
                    {item.title}
                  </h2>
                  <br />
                  <a
                    href={item.buttonUrl}
                    target="_blank"
                    rel="noreferrer"
                    className="grid gap-2 d-md-block btn btn-outline-light btn-md invest-image-button"
                  >
                    {item.buttonName}
                  </a>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          )
        })}
      </Carousel>
    </>
  )
}

/** export */
export default HeroImage
