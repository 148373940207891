import React from "react"

interface Props {
  company: string
  title: string
  subtitle: string
  buttonName?: string
  buttonUrl?: string
}

/** const */
const InvestIRASteps: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section className="container-fluid pt-5 pb-0" style={{ background: "#e1e1e1" }}>
      <div className="container pl-1 pr-1">
        <div className="contentHeroSpace hidden-xs"></div>
        <div className="container-fluid p-0 ms-3 me-3 pe-4" style={{ background: "#e1e1e1", color: "#111111" }}>
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div className="mt-1" style={{ backgroundColor: "#51976f", width: "100%", height: "4px" }}></div>
              <div className="mb-3 invest-stats-title">Invest your IRA in three simple steps</div>
              <div className="mb-0 invest-stats-text mb-2" style={{ width: "100%" }}>
                Unlock the power of your IRA with Norhart Invest and turn your investment into the gift of time. Our
                seamless process guides you through creating a future where every second is yours to enjoy. Here's how
                you can start claiming time today:
              </div>
              <div className="mt-0 mb-3 invest-stats-text mb-5" style={{ width: "100%", fontWeight: "600" }}></div>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Learn</div>
                      <div className="invest-ira-rates-text">
                        Dive into our webinar for a clear understanding on how Norhart Invest primes your IRA for more
                        than just financial growth.
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Transparent Terms</div>
                      <div className="invest-ira-rates-text">
                        With our real team of investment specialists to align your financial goals with life’s
                        priorities. No jargon, just clear, actionable advice.
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Thrive</div>
                      <div className="invest-ira-rates-text">
                        Launch your investment and embrace a future where your wealth and time blossom.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 invest-cta-right">{props.subtitle}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default InvestIRASteps
