import React from "react"

interface Props {
  company: string
  title: string
  subtitle: string
  buttonName?: string
  buttonUrl?: string
}

/** const */
const InvestIRARates: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section className="container-fluid pt-5 pb-0" style={{ background: "#e1e1e1" }}>
      <div className="container pl-1 pr-1">
        <div className="contentHeroSpace hidden-xs"></div>
        <div className="container-fluid p-0 ms-3 me-3 pe-4" style={{ background: "#e1e1e1", color: "#111111" }}>
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div className="mt-1" style={{ backgroundColor: "#51976f", width: "100%", height: "4px" }}></div>
              <div className="mb-3 invest-stats-title">Invest. Enjoy. Repeat.</div>
              <div className="mb-0 invest-stats-text mb-2" style={{ width: "100%" }}></div>
              <div className="mt-0 mb-3 invest-stats-text mb-5" style={{ width: "100%", fontWeight: "600" }}></div>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-2" style={{ backgroundColor: "#000000", color: "#ffffff" }}>
                    <div>
                      <div className="invest-rates-numbers">7.6%</div>
                      <div className="invest-ira-rates-text">6 Month</div>
                      <div className="invest-rates-subtext">Annual Yield</div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <div className="invest-rates-numbers">7.9%</div>
                      <div className="invest-ira-rates-text">9 Month</div>
                      <div className="invest-rates-subtext">Annual Yield</div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <div className="invest-rates-numbers">8.4%</div>
                      <div className="invest-ira-rates-text">12 Month</div>
                      <div className="invest-rates-subtext">Annual Yield</div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <div className="invest-rates-numbers">8.45%</div>
                      <div className="invest-ira-rates-text">18 Month</div>
                      <div className="invest-rates-subtext">Annual Yield</div>
                    </div>
                  </div>

                  <div className="col-sm-2">
                    <div>
                      <div className="invest-rates-numbers">8.5%</div>
                      <div className="invest-ira-rates-text">24 Month</div>
                      <div className="invest-rates-subtext">Annual Yield</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 invest-cta-right">{props.subtitle}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default InvestIRARates
