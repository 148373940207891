import React, { FunctionComponent, ReactNode } from "react"
import { useCalculatorSection } from "../../hooks/useCalculatorSection"

/** props */
interface Props {
  backgroundColorOne: string
  backgroundColorTwo: string
  title: string
  subTitle?: string
  formatSubTitle?: () => ReactNode
}

const LANDING_INITIAL_TERM = "60"
const MINIMUM_AMOUNT = 2500

/** const */
const InvestCalculatorSectionNew: FunctionComponent<Props> = ({
  backgroundColorOne,
  backgroundColorTwo,
  title,
  subTitle,
  formatSubTitle,
}) => {
  const { principal, term, interest, earned, onChangeAmount, onChangeTerm } = useCalculatorSection({
    initialTerm: LANDING_INITIAL_TERM,
    initialAmount: MINIMUM_AMOUNT.toString(),
    isLanding: true,
  })

  const renderedSubTitle = subTitle ?? formatSubTitle()

  return (
    <section
      className="container-fluid pt-0 pb-0"
      style={{
        background: `linear-gradient(${backgroundColorOne} 30%, ${backgroundColorTwo} 70%)`,
      }}
    >
      <div className="container pl-3 pr-3 text-center">
        <div className="container pb-5"></div>

        <h3 className="contentHeroTitle mt-4" style={{ color: "#111111" }}>
          {title}
        </h3>
        <h2 className="contentHeroSubTitle mb-0" style={{ color: "#111111", opacity: "0.8" }}>
          {renderedSubTitle}
        </h2>

        {/* CALCULATE LINE BREAK TOP */}
        <div className="text-center pt-4">
          <hr />
        </div>

        {/* CALCULATE INPUTS */}
        {/* <div className="invest-calculate-title text-center pb-0" style={{ color: "#111111", opacity: "0.8" }}>
          Calculate
        </div> */}

        <div className="container invest-size  mt-0 text-black">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <div className="input-group invest-input-amount" style={{ color: "#555555", fontWeight: "bold" }}>
                <div className="form-floating">
                  <input
                    className="form-control invest-input-amount"
                    type="text"
                    placeholder="Amount"
                    id="amount"
                    name="amount"
                    style={{
                      borderRadius: "4px 4px 4px 4px",
                      backgroundColor: "#cccccc",
                      color: "#555555",
                      border: "#cccccc",
                      fontWeight: "bold",
                    }}
                    value={principal}
                    onChange={onChangeAmount}
                    maxLength={9}
                  />

                  <label className="d-none d-md-block d-lg-block d-xl-block" htmlFor="amount">
                    Amount (USD)
                  </label>
                  <label className="d-md-none d-lg-none d-xl-none" htmlFor="amount">
                    Amount
                  </label>
                </div>
              </div>
            </div>

            <div className="m-1"></div>

            <div>
              <div className="form-floating invest-input-term" style={{ color: "#555555", fontWeight: "bold" }}>
                <select
                  className="form-select invest-input-term"
                  name="term"
                  style={{ backgroundColor: "#cccccc", color: "#555555", border: "#cccccc", fontWeight: "bold" }}
                  value={term}
                  onChange={onChangeTerm}
                >
                  {/* <option value="flexible">Flexible</option> */}
                  <option value="6">6 months</option>
                  <option value="9">9 months</option>
                  <option value="12">12 months</option>
                  <option value="18">18 months</option>
                  <option value="24">24 months</option>
                  <option value="36">36 months</option>
                  <option value="60">60 months</option>
                </select>
                <label htmlFor="term">Term</label>
              </div>
            </div>

            <div className="m-1"></div>

            <div>
              <div className="form-floating invest-input-rate" style={{ color: "#111111", fontWeight: "bold" }}>
                <div style={{ backgroundColor: "transparent", color: "#555555", fontWeight: "bold", fontSize: "30px" }}>
                  {interest}% <span style={{ fontSize: "22px", float: "left", opacity: "0.7" }}>APY</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CALCULATE LINE BREAK BOTTOM */}
        <div className="text-center mb-2">
          <hr />
        </div>

        {/* <div className="invest-earn-text text-center" style={{color: "#198754" }}>Earn</div> */}

        {/* CALCULATE NUMBER */}
        <div className="invest-calculate-text-dark text-center">
          {earned}
          <span style={{ fontSize: "16px", color: "#999999" }}>USD</span>
        </div>

        <div className="invest-paid-text text-center" style={{ fontSize: "16px", color: "#999999" }}>
          {term === "flexible" ? "Paid Annually" : "Paid At End Of Term"}
        </div>
      </div>
    </section>
  )
}
/** export */
export default InvestCalculatorSectionNew
