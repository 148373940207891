import React from "react"
import { Helmet } from "react-helmet"
import config from "../../site.config"
import { graphql } from "gatsby"

/** components */
import Layout from "../components/Layout"
import { theme } from "../components/Theme"

/** static sections */
import FAQInvestSection from "../components/static-sections/FAQInvestSection"
import InvestLegalSection from "../components/static-sections/InvestLegalSection"
import InvestIRAApplyMockConcept from "../components/static-sections/InvestIRAApplyMockConcept"
import InvestIRAApplyMock from "../components/static-sections/InvestIRAApplyMock"
import InvestOfferings from "../components/static-sections/InvestOfferings"
import IraOfferings from "../components/static-sections/IraOfferings"
import HeroInvestGallery from "../components/static-sections/HeroInvestGallery"

/** dynamic sections */
import HeroBottomInvestTextSection from "../components/dynamic-sections/HeroBottomInvestTextSection"
import HeroInvestImageHero from "../components/dynamic-sections/HeroInvestImageHero"
import HeroVideo from "../components/dynamic-sections/HeroVideo"
import HeroImage from "../components/dynamic-sections/HeroImage"
import HeroInvestImage from "../components/dynamic-sections/HeroInvestImage"
import InvestRibbionSignup from "../components/dynamic-sections/InvestRibbionSignup"
import HeroBottomInvestLandingCustomSection from "../components/dynamic-sections/HeroBottomInvestLandingCustomSection"
import TestimonialSection from "../components/dynamic-sections/testimonial-setcion/TestimonialSection"
import InvestCalculatorSectionNew from "../components/dynamic-sections/InvestCalculatorSectionNew"
import InvestIRAStats from "../components/dynamic-sections/InvestIRAStats"
import InvestStats from "../components/dynamic-sections/InvestStats"
import InvestIRARates from "../components/dynamic-sections/InvestIRARates"
import InvestIRASteps from "../components/dynamic-sections/InvestIRASteps"
import InvestIRAPromise from "../components/dynamic-sections/InvestIRAPromise"
import InvestRates from "../components/dynamic-sections/InvestRates"
import HeroRightSection from "../components/dynamic-sections/HeroRightSection"
import HeroInvestRightSection from "../components/dynamic-sections/HeroInvestRightSection"
import HeroInvestLinkButtons from "../components/dynamic-sections/HeroInvestLinkButtons"

/** svg */
import NorhartBlackCircleIcon from "../../assets/norhart-black-circle-icon.svg"

/** color theme */
const colorPalette = theme.colorPalettes.invest

/** props */
interface Props {
  data?: any
  didSubmit: boolean
}

/** const */
export const InvestIraPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/invest-ira/",
          name: "Invest IRA and 401K | Norhart",
          image: `${config.siteMetadata.siteUrl}/invest/norhart-invest-ira-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Invest IRA and 401K  | Norhart"
      description="Norhart invest platform. Our invest platform will offer up to 10% APY interest rate returns, and all investments are backed by real estate."
      keywords="norhart, invest, investment, promissory note, real estate, investment, fintech, tax benefits, wealth"
      imageTwitter={`${config.siteMetadata.siteUrl}/invest/norhart-invest-ira-hero-twitter-card.png`}
      imageOpenGraph={`${config.siteMetadata.siteUrl}/invest/norhart-invest-ira-hero-open-graph.png`}
      colorPalette={colorPalette}
    >
      <HeroVideo
        title="Invest your IRA, buy your time back."
        subtitle="Invest for the future, where every moment is yours to cherish. Enjoy up to 8. % returns on your IRA, turning savings into the currency of time. With Norhart Invest, tomorrow’s freedom starts today."
        buttonName1="Invest Now"
        buttonUrl1="https://invest.norhart.com/signup"
        videoID="2"
        videoFile="/invest/video/norhart-invest-ira-hero.mp4"
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <InvestCalculatorSectionNew
        backgroundColorOne="#e1e1e1"
        backgroundColorTwo="#e1e1e1"
        title="How Much Can You Make"
        subTitle="Select an amount to calculate your potential Norhart Invest return."
      />

      <InvestIRAPromise />

      <HeroInvestImageHero imageHero="/invest/norhart-invest-ira-open-to-all-investors.png" />

      <InvestIRARates />

      <InvestIRASteps />

      <HeroBottomInvestLandingCustomSection
        backgroundColorOne="#f4f4f4"
        backgroundColorTwo="#f4f4f4"
        icon="{NorhartBlackCircleIcon}"
        iconTitle="Norhart Invest"
        title="Meet Our Real CEO, Mike Kaeding"
        subTitle="Mike is a real guy, like you, and us. He is the CEO of Norhart.
        In our first-ever webinar, Mike will tell you What Norhart Invest is, and How your hard-earned money is invested, and protected by the SEC."
        image="/invest/norhart-invest-capital-flow.png"
        imageAlt="Norhart CEO Mike Kaeding Video"
        textColor="#111111"
        calcultateColor="#198754"
        inputColor="#198754"
        posterFrame="/invest/invest-poster-frame.png"
      />

      <HeroImage
        items={[
          {
            name: "Mike Kaeding",
            title: "CHIEF EXECUTIVE OFFICER",
            image: "/invest/leadership/mike-kaeding-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/mikekaeding/",
          },
          {
            name: "Stacy Ekholm",
            title: "CHIEF OPERATING OFFICER",
            image: "/invest/leadership/stacy-ekholm-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/stacy-ekholm-cpm-cos-crm-cmca-a587647b/",
          },
          {
            name: "Tim Libertini",
            title: "CHIEF FINANCIAL OFFICER",
            image: "/invest/leadership/tim-libertini-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/timothy-libertini-81701744/",
          },
          {
            name: "Marie Dickover",
            title: "CHIEF DEVELOPMENT OFFICER",
            image: "/invest/leadership/marie-dickover-leadership.png",
            buttonName: "Connect Today",
            buttonUrl: "https://www.linkedin.com/in/marie-dickover-178095225/",
          },
        ]}
      />

      <InvestIRAApplyMockConcept
        backgroundColorOne="#f0f0f0"
        backgroundColorTwo="#f0f0f0"
        badgeTitle="Get Started Today"
        colorPalette={colorPalette}
        colSizeOne={5}
        colSizeTwo={7}
        fontTextColor="#111111"
        image="/invest/norhart-invest-platform-mobile-dark.png"
        imageTitle="Invest Signup"
        title="Connect Now"
        subtitle=""
        titleTopLeft="Norhart IRA and 401K"
        asoEffect="fade"
        rateImage="/invest/8-point-5-percent-apy.png"
        // buttonName="Login"
        // buttonUrl="https://invest.norhart.com/"
      />

      <InvestIRAPromise />

      <InvestLegalSection />
    </Layout>
  )
}

/** export */
/** export */
export default InvestIraPage
