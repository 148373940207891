import React from "react"

interface Props {
  company: string
  title: string
  subtitle: string
  buttonName?: string
  buttonUrl?: string
}

/** const */
const InvestIRAPromise: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section className="container-fluid pt-5 pb-0" style={{ background: "#e1e1e1" }}>
      <div className="container pl-1 pr-1">
        <div className="contentHeroSpace hidden-xs"></div>
        <div className="container-fluid p-0 ms-3 me-3 pe-4" style={{ background: "#e1e1e1", color: "#111111" }}>
          <div className="row align-items-start justify-content-start">
            <div className="col-sm-12">
              <div className="mt-1" style={{ backgroundColor: "#51976f", width: "100%", height: "4px" }}></div>
              <div className="mb-3 invest-stats-title">Elevate Your IRA With Our Promise</div>
              <div className="mb-0 invest-stats-text mb-2" style={{ width: "100%" }}></div>
              <div className="mt-0 mb-3 invest-stats-text mb-5" style={{ width: "100%", fontWeight: "600" }}></div>

              <div className="container ms-0 p-0">
                <div className="row">
                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Effortless Transitions</div>
                      <div className="invest-ira-rates-text">
                        Our skilled team guides you seamlessly through the rollover process, ensuring your switch to
                        Norhart Invest is smooth and straightforward.
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Transparent Terms</div>
                      <div className="invest-ira-rates-text">
                        Invest with confidence knowing there are no hidden fees to surprise you. We're here to grow your
                        investment, not chip away at it.
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Top-Tier Returns</div>
                      <div className="invest-ira-rates-text">
                        Expect more from your money with competitive yields designed to enhance your portfolio's
                        performance, giving you freedom and flexibility for your future.
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div>
                      <div className="invest-rates-numbers">Smart Tax Benefits</div>
                      <div className="invest-ira-rates-text">
                        Enjoy substantial earnings with the added advantage of tax efficiency, keeping more of your
                        investment gains working for you.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 invest-cta-right">{props.subtitle}</div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default InvestIRAPromise
