import React from "react"

interface Props {
  videoID: string
  title: string
  subtitle: string
  buttonName1?: string
  buttonUrl1?: string
  videoFile: string
}

/** const */
const HeroVideo: React.FC<Props> = (props) => {
  const Icon = props.icon

  return (
    <section id={props.videoID} className="d-flex align-items-center">
      <video autoPlay muted loop className="w-100 darker-image">
        <source src={props.videoFile} type="video/mp4" />
      </video>

      <div
        className="invest-video-hero position-absolute container-sm w-100 mt-0 pt-0"
        data-aos="fade-up"
        data-aos-delay="300"
      >
        <h1 className="invest-video-text">{props.title}</h1>
        <h5 className="invest-video-subtext">{props.subtitle}</h5>
        <div className="container mt-0 invest-video-button">
          <div class="row">
            <div className="col">
              <a href={props.buttonUrl1} target="_blank" rel="noreferrer">
                <button type="button" className="invest-video-button btn btn-success btn-md">
                  {props.buttonName1}
                </button>
              </a>
              {props.buttonUrl2 && (
                <a href={props.buttonUrl2} target="_blank" rel="noreferrer">
                  <button type="button" className="invest-video-button btn btn-success btn-md">
                    {props.buttonName2}
                  </button>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

/** export */
export default HeroVideo
