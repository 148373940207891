const daysInFutureFromMonths = (months: number) => {
  const currentDate = new Date()
  const startDateTime = new Date(currentDate.getTime())
  const endDate = new Date(startDateTime.setMonth(startDateTime.getMonth() + months))
  const timeDifference: number = endDate.getTime() - currentDate.getTime()
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  return daysDifference
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const calculateEarned = (term: string, foundRate: string, initialAmount: number) => {
  const monthsTerm = term === "flexible" ? 12 : Number(term)
  const days = daysInFutureFromMonths(monthsTerm) + 1
  const parsedRate = Number(foundRate) / 100
  const dailyInterest = Math.pow(1 + parsedRate, 1 / 365) - 1
  const totalInterest = Math.pow(1 + dailyInterest, days)
  const result = initialAmount * totalInterest
  const earned = currencyFormatter.format(result)
  const rate = `${foundRate}%`
  return { earned, rate }
}

const calculate = (principal: string, term: string, interest = "0") => {
  const parsedPrincipal = parseInt(principal, 10)
  if (isNaN(parsedPrincipal)) {
    return { earned: "$0", rate: "" }
  } else {
    const { rate, earned } = calculateEarned(term, interest, parsedPrincipal)
    return { rate, earned }
  }
}

const termToInterest: Record<string, string> = {
  flexible: "4.51",
  "6": "7.60",
  "9": "7.90",
  "12": "8.40",
  "18": "8.45",
  "24": "8.50",
  "36": "9.0",
  "60": "10.0",
}

export const Interest = {
  calculate,
  termToInterest,
}
